let apiUrl = "";
if(process.env.NODE_ENV === "renewal"){
    apiUrl = 'https://renewal-api.tablenjoy.com:30081';
}else{
    apiUrl = 'https://renewal.tablenjoy.com:30080'; //https://test-api.tablenjoy.com:30080
}
// apiUrl = 'https://test-api.tablenjoy.com:30080';
// apiUrl = 'https://renewal.tablenjoy.com:30080';
export default {
    // server:"http://tablenjoy.duckdns.org:30080",//개발 서버
    server: process.env.NODE_ENV === 'production' ? "https://api.tablenjoy.com" : `${apiUrl}`,//운영 서버
    sitename:"tablenjoy",
    // insideip:"39.115.113.202",
    insideip:"210.126.8.215",
    appKey: "3ee9792afeed94b7fcc876e82d258b73",
}

//http://183.111.227.112:8089/
